<template>
  <v-app>
    <v-tour
      name="myTour"
      :steps="steps"
      :callbacks="myCallbacks"
      :options="myOptions"
    />
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />

    <dashboard-core-settings />
    <!-- para soporte al cliente -->
    <!-- <beautiful-chat /> -->
    <!-- <a href="https://api.whatsapp.com/send/?phone=593988511884&text&app_absent=0"
      class="float" target="_blank">
      <i class="fa fa-whatsapp my-float"></i>
    </a> -->
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
      BeautifulChat: () => import('./component/beautiful-chat/beautiful-chat'),
    },

    data: () => ({
      expandOnHover: false,
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Salir del Tour',
          buttonPrevious: 'Anterior',
          buttonNext: 'Siguiente',
          buttonStop: 'Finalizar',
        },
      },
      myCallbacks: {},
      steps: [],
    }),
    created () {
      this.myCallbacks = {
        onNextStep: this.myCustomNextStepCallback,
      }
      this.steps = [
        {
          target: '#core-navigation-drawer',
          header: {
            title: 'MENÚ PRINCIPAL',
          },
          content: 'Menú principal de <strong>OPCIONES</strong> para navegar dentro del aplicativo.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'left',
          },
        },
        {
          target: '#settings',
          header: {
            title: 'CONFIGURACIONES',
          },
          content: 'Configura los colores e imagen de tu aplicativo y la <strong>VISUALIAZACIÓN</strong> de este tour.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'right',
          },
        },
        {
          target: '#userprofile',
          header: {
            title: 'PERFIL DE USUARIO',
          },
          content: 'Es importante agregar los datos de su <strong>PERFIL</strong> para la visualización de los mismos dentro de los reportes que se generan dentro de la aplicación.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'center',
          },
          before: type => new Promise((resolve, reject) => {
            this.$router.push('/dashboard/pages/user')
            resolve('foo')
          }),
        },
        {
          target: '#precios-procedimientos-view',
          header: {
            title: 'PRECIOS Y PROCEDIMIENTOS',
          },
          content: 'Administra los <strong>PRECIOS</strong> de tus <strong>PROCEDIMIENTOS</strong> agrega los procedimientos que se adaptan a tus procesos y guarda los precios de cada uno.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'top',
          },
          before: type => new Promise((resolve, reject) => {
            this.$router.push('/dashboard/pages/configPrice')
            resolve('foo')
          }),
        },
        {
          target: '#precios-procedimientos-1-view',
          header: {
            title: 'PROCEDIMIENTOS TOTALES',
          },
          content: 'Escoge los <strong>PROCEDIMIENTOS</strong> de la lista completa para agregarlos a tus <strong>PROCEDIMIENTOS</strong> locales.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'left',
          },
        },
        {
          target: '#precios-procedimientos-2-view',
          header: {
            title: 'PROCEDIMIENTOS AGREGADOS',
          },
          content: 'Agrega los <strong>PRECIOS</strong> de cada <strong>PROCEDIMIENTO</strong> para poder utilizar los mismos dentro del ODONTOGRAMA.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'right',
          },
        },
        {
          target: '#precios-procedimientos-3-view',
          header: {
            title: 'GUARDAR DATOS',
          },
          content: 'Guarde lo <strong>DATOS Y PRECIOS</strong> agregados para cada <strong>PROCEDIMIENTO</strong> caso contrario no se verán reflejados los mismos dentro del <strong>ODONTOGRAMA</strong>.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'top',
          },
        },
        {
          target: '#patients-view',
          header: {
            title: 'PACIENTES',
          },
          content: 'Administra tus <strong>PACIENTES</strong> agrega, edita y visualiza los datos de tus pacientes.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'top',
          },
          before: type => new Promise((resolve, reject) => {
            this.$router.push('/dashboard/pages/patienttable')
            resolve('foo')
          }),
        },
        {
          target: '#patients-view-1',
          header: {
            title: 'AGREGAR PACIENTE',
          },
          content: 'Agrega un nuevo <strong>PACIENTE</strong> genera la anamnesis del mismo. Genera <strong>PREGUNTAS FRECUENTES</strong> ten presente que es obligatorio agregar tus pacientes para poder utilizar de manera correcta el aplicativo.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'top',
          },
        },
        {
          target: '#odontograma-step',
          header: {
            title: 'ODONTORAMA',
          },
          content: 'Administra el <strong>ODONTOGRAMA</strong> de cada <strong>TRATAMIENTO</strong> por paciente.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'top',
          },
          before: type => new Promise((resolve, reject) => {
            this.$router.push('/dashboard/pages/odontogram')
            resolve('foo')
          }),
        },
        {
          target: '#nuevo-tratamiento',
          header: {
            title: 'NUEVO TRATAMIENTO',
          },
          content: 'Genera un <strong>NUEVO TRATAMIENTO</strong> agregando los <strong>PROCEDIMIENTOS PARA EL MISMO</strong> por paciente.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'top',
          },
        },
        {
          target: '#agregar-procedimiento',
          header: {
            title: 'AGREGAR PROCEDIMIENTO',
          },
          content: 'Para agregar un <strong>PROCEDIMIENTO</strong> debe seleccionar una <strong>PIEZA DEL ODONTOGRAMA</strong> y verificar que tengas el procedimiento previamente cargado.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'left',
          },
        },
        {
          target: '#guardar-tratamiento',
          header: {
            title: 'GUARDAR TRATAMIENTO',
          },
          content: 'Guarda los datos de tu nuevo <strong>TRATAMIENTO</strong>.',
          params: {
            // You can control the position of the tour popup easily.
            placement: 'top',
          },
        },
      ]
    },
    methods: {
      myCustomNextStepCallback (currentStep) {
        // setTimeout(() => {
        //   this.$tours.myTour.next(parseInt(currentStep) + 1)
        // }, 1000)
        // this.$tours.myTour.next(currentStep)
      },
    },
  }
</script>

<style scoped>
   /* para el boton flotante  */
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }

  .my-float {
    margin-top: 16px;
  }
</style>